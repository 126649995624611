import { motion } from 'framer-motion';

export default function FollowSection() {
  const steps = [
    {
      title: "Discovery",
      description: "We start by understanding your vision, requirements and business goals through detailed discussions.",
      icon: "🎯"
    },
    {
      title: "Planning & Strategy", 
      description: "Our team creates a comprehensive roadmap with technical specifications, timeline and milestones.",
      icon: "📋"
    },
    {
      title: "Design & Prototyping",
      description: "We design intuitive user interfaces and create interactive prototypes for your approval.",
      icon: "🎨"
    },
    {
      title: "Development",
      description: "Our developers bring the designs to life using cutting-edge technologies and best practices.",
      icon: "👨‍💻"
    },
    {
      title: "Testing & QA",
      description: "Rigorous testing ensures your product is bug-free and performs optimally across all platforms.",
      icon: "🔍"
    },
    {
      title: "Deployment",
      description: "We launch your product and provide comprehensive documentation and training.",
      icon: "🚀"
    },
    {
      title: "Maintenance & Support",
      description: "Ongoing support, updates and improvements to keep your product running smoothly.",
      icon: "🛠️"
    }
  ];

  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8" id="process">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="max-w-6xl mx-auto"
      >
        <div className="space-y-16">
          {steps.map((step, index) => (
            <motion.div
              key={step.title}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="group relative"
            >
              <div className="flex items-center gap-8">
                <div className="absolute w-0.5 h-full bg-gradient-to-b from-accent-500 to-primary-500 left-[2.5rem] -z-10">
                  {index !== steps.length - 1 && <div className="h-full" />}
                </div>
                <motion.div 
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                  className="w-20 h-20 flex-shrink-0 relative group"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-accent-500 via-primary-500 to-secondary-500 rounded-full opacity-50 blur-lg group-hover:opacity-75 transition-opacity duration-300"></div>
                  <div className="relative w-full h-full bg-black/50 backdrop-blur-sm rounded-full border-2 border-white/20 flex items-center justify-center text-3xl shadow-lg">
                    <span className="bg-gradient-to-r from-cyan-400 to-blue-400 bg-clip-text text-transparent group-hover:scale-110 transition-transform duration-300">
                      {step.icon}
                    </span>
                  </div>
                </motion.div>
                <div className="flex-1 bg-white/5 backdrop-blur-lg p-6 rounded-2xl border border-white/10 hover:border-cyan-500/50 transition-all duration-300 shadow-lg">
                  <motion.h3 
                    className="text-2xl font-bold bg-gradient-to-r from-accent-400 to-primary-400 bg-clip-text text-transparent mb-3"
                  >
                    {step.title}
                  </motion.h3>
                  <p className="text-white/80 text-lg leading-relaxed">{step.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
}
