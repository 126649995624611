import { motion } from 'framer-motion';

export default function NavBar() {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return(
    <motion.nav 
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="fixed top-5 inset-x-0 mx-auto max-w-fit bg-black/90 backdrop-blur-md rounded-full px-6 py-2 border border-white/20 z-50"
    >
      <div className="flex items-center justify-between w-full text-white/90 md:space-x-16">
        <motion.div 
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="font-medium hidden md:block"
        >
          Piranha Codes
        </motion.div>
        <div className="flex items-center gap-8">
          {['home', 'services', 'contact'].map((item, index) => (
            <motion.a
              key={item}
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(item);
              }}
              href={`#${item}`}
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.3 + index * 0.1, duration: 0.5 }}
              whileHover={{ scale: 1.1 }}
              className="hover:text-white transition-colors capitalize cursor-pointer"
            >
              {item}
            </motion.a>
          ))}
        </div>
      </div>
    </motion.nav>
  );
}
