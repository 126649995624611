import { motion } from 'framer-motion';

export default function HeroSection() {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      id="home" 
      className="min-h-screen flex justify-center items-center px-4 sm:px-6 lg:px-8 py-16 sm:py-20 pt-20 relative"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
        className="absolute inset-0 pointer-events-none overflow-hidden w-full h-full"
      >
        <div className="absolute inset-0 w-full h-full bg-gradient-to-br from-black/95 via-black/90 to-black/95">
          {/* Floating blockchain elements */}
          {[...Array(15)].map((_, i) => (
            <motion.div
              key={i}
              initial={{ 
                opacity: 0,
                y: Math.random() * window.innerHeight,
                x: Math.random() * window.innerWidth,
                scale: 0.5
              }}
              animate={{
                opacity: [0, 0.6, 0.6, 0],
                y: [0, -800],
                x: `${Math.sin(i * 0.5) * window.innerWidth/8}px`,
                scale: [0.5, 1.2, 1.2, 0.5]
              }}
              transition={{
                duration: 10 + Math.random() * 8,
                repeat: Infinity,
                delay: i * 0.5
              }}
              className="absolute text-xl md:text-2xl font-mono"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                color: `hsla(${Math.random() * 360}, 70%, 50%, 0.15)`
              }}
            >
              {['ETH', 'BTC', 'NFT', '🤖', '🧠', '⚡', '⛓️', '🔐', 'AI'][Math.floor(Math.random() * 9)]}
            </motion.div>
          ))}

          {/* AI/ML Pattern Flow */}
          {[...Array(8)].map((_, i) => (
            <motion.div
              key={`neural-${i}`}
              initial={{ y: -200, x: `${(i * 12.5)}%` }}
              animate={{ y: '120vh' }}
              transition={{
                duration: 15,
                repeat: Infinity,
                ease: 'linear',
                delay: i * 1.2
              }}
              className="absolute w-[1px] text-xs font-mono tracking-tight"
              style={{
                left: `${i * (100/8)}%`,
                color: `hsla(${i * 45}, 70%, 50%, 0.15)`
              }}
            >
              {Array(20).fill(0).map(() => ['01', '10', 'AI', 'ML'][Math.floor(Math.random() * 4)]).join('\n')}
            </motion.div>
          ))}

          {/* Web3 Network Nodes */}
          <div className="absolute inset-0 w-full h-full">
            {[...Array(15)].map((_, i) => (
              <motion.div
                key={`node-${i}`}
                initial={{
                  scale: 0,
                  x: `${Math.random() * 100}%`,
                  y: `${Math.random() * 100}%`,
                  rotate: Math.random() * 180
                }}
                animate={{
                  scale: [0, 1.8, 1.8, 0],
                  opacity: [0, 0.5, 0.5, 0], // Reduced opacity from 0.8 to 0.5
                  rotate: [0, 180, 360, 540],
                  boxShadow: [
                    '0 0 0px rgba(255,255,255,0)', 
                    '0 0 40px rgba(124,58,237,0.2)', // Reduced shadow opacity from 0.4 to 0.2
                    '0 0 40px rgba(34,211,238,0.2)', // Reduced shadow opacity from 0.4 to 0.2
                    '0 0 0px rgba(255,255,255,0)'
                  ]
                }}
                transition={{
                  duration: 8,
                  repeat: Infinity,
                  delay: i * 0.5,
                  ease: "easeInOut"
                }}
                className="absolute w-5 h-5 rounded-full bg-gradient-to-r from-secondary-500/30 via-primary-500/30 to-accent-500/30 backdrop-blur-sm" // Reduced opacity from /50 to /30
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  filter: 'blur(1px)'
                }}
              >
                <div className="absolute inset-0 rounded-full bg-gradient-to-r from-accent-400/20 to-secondary-400/20 animate-pulse" /> {/* Reduced opacity from /30 to /20 */}
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>
      <div className="w-full max-w-4xl mx-auto space-y-8 sm:space-y-12 lg:space-y-16 text-center relative z-10">
        <motion.div 
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="space-y-3 sm:space-y-4 lg:space-y-6"
        >
          <motion.h1
            initial={{ scale: 0.8, opacity: 0, rotateX: -30 }}
            animate={{ scale: 1, opacity: 1, rotateX: 0 }}
            transition={{ 
              duration: 1,
              delay: 0.4,
              type: "spring",
              stiffness: 100
            }}
            className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold bg-gradient-to-r from-accent-400 via-primary-500 to-secondary-500 bg-clip-text text-transparent animate-gradient-x hover:scale-105 transition-transform cursor-default tracking-tight leading-tight px-4"
          >
            Innovating Through
            <br className="sm:hidden" />
            {" "}Digital Excellence
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="text-base sm:text-lg md:text-xl text-white/90 leading-relaxed max-w-2xl mx-auto backdrop-blur-sm px-4"
          >
            Transforming ideas into reality with cutting-edge AI and cloud-native architecture. Experience enterprise-grade solutions with startup speed and agility.
          </motion.p>
        </motion.div>
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.8 }}
          className="flex flex-col sm:flex-row items-center justify-center gap-3 sm:gap-4 lg:gap-6 w-full max-w-xl mx-auto px-4"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="group w-full sm:w-auto px-4 sm:px-6 lg:px-8 py-3 lg:py-4 bg-gradient-to-r from-accent-500 via-primary-500 to-secondary-500 text-white rounded-full font-medium transition-all duration-300 flex items-center justify-center gap-2"
          >
            <span className="whitespace-nowrap text-sm sm:text-base">Get Started</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 group-hover:translate-x-1 transition-transform" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </motion.button>
          <motion.a
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            href="#services"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection('services');
            }}
            className="group w-full sm:w-auto px-4 sm:px-6 lg:px-8 py-3 lg:py-4 bg-black/30 backdrop-blur-md text-white rounded-full font-medium hover:bg-white/10 transition-all duration-300 flex items-center justify-center gap-2 border border-white/20"
          >
            <span className="whitespace-nowrap text-sm sm:text-base">Explore Solutions</span>
          </motion.a>
        </motion.div>
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 1 }}
          className="grid grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4 lg:gap-6 px-4"
        >
          {[
            { number: "10+", text: "Clients Served" },
            { number: "15+", text: "Team Members" },
            { number: "99.9%", text: "Uptime Guaranteed" },
            { number: "24/7", text: "Enterprise Support" }
          ].map((item, index) => (
            <motion.div
              key={item.text}
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1.2 + index * 0.1 }}
              className="bg-black/30 backdrop-blur-md p-4 sm:p-5 lg:p-6 rounded-2xl border border-white/20"
            >
              <div className="text-2xl sm:text-3xl font-bold text-white mb-1 sm:mb-2">{item.number}</div>
              <div className="text-sm sm:text-base text-white/80">{item.text}</div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
}
