import { motion } from 'framer-motion';

export default function TechSection() {
  const technologies = [
    { name: 'React', icon: '⚛️', description: 'Frontend Development', gradient: 'from-accent-400 to-primary-500' },
    { name: 'Node.js', icon: '🟢', description: 'Backend Development', gradient: 'from-primary-400 to-secondary-500' },
    { name: 'Python', icon: '🐍', description: 'AI & Automation', gradient: 'from-secondary-400 to-accent-500' },
    { name: 'AWS', icon: '☁️', description: 'Cloud Infrastructure', gradient: 'from-accent-400 to-primary-500' },
    { name: 'MongoDB', icon: '🍃', description: 'Database', gradient: 'from-primary-400 to-secondary-500' },
    { name: 'Docker', icon: '🐳', description: 'Containerization', gradient: 'from-secondary-400 to-accent-500' },
    { name: 'TensorFlow', icon: '🤖', description: 'Machine Learning', gradient: 'from-accent-400 to-primary-500' },
    { name: 'React Native', icon: '📱', description: 'Mobile Development', gradient: 'from-primary-400 to-secondary-500' }
  ];

  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="max-w-7xl mx-auto"
      >
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {technologies.map((tech, index) => (
            <motion.div
              key={tech.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="group relative"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-white/10 rounded-[2rem] blur-xl group-hover:blur-2xl transition-all duration-500 opacity-0 group-hover:opacity-100" />
              
              <div className="relative bg-black/40 backdrop-blur-xl rounded-[2rem] p-8 h-full border border-white/10 hover:border-white/25 transition-all duration-500 overflow-hidden">
                <div className={`absolute inset-0 bg-gradient-to-br ${tech.gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />
                
                <motion.div 
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  transition={{ duration: 0.3 }}
                  className="text-5xl mb-6 transform group-hover:-translate-y-1 transition-transform duration-300"
                >
                  {tech.icon}
                </motion.div>
                
                <h3 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-white/80 group-hover:bg-gradient-to-r group-hover:from-cyan-400 group-hover:to-blue-500 transition-all duration-500 mb-3">
                  {tech.name}
                </h3>
                
                <p className="text-white/60 group-hover:text-white/80 transition-colors duration-500 text-sm">
                  {tech.description}
                </p>

                <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                  <motion.div
                    whileHover={{ scale: 1.2, rotate: 90 }}
                    className="w-6 h-6 text-white/40"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
}
